@import 'primeflex3/core/utils';

.p-dropdown {
    border-color: var(--surface-100);
    border-radius: var(--border-radius);

    .p-dropdown-clear-icon {
        margin-top: -0.68rem;
    }

    &.p-disabled {
        background-color: var(--surface-50);
    }
}

.p-dropdown-filter-icon {
    margin-top: -0.68rem !important;
}

[ng-reflect-readonly='true'] > .p-dropdown {
    background-color: var(--surface-50);
}
