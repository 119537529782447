@import 'primeflex3/core/utils';

.p-field {
    margin-bottom: 1.5rem;

    > label {
        @include styleclass('font-semibold');
    }
}

.p-inputtext {
    font-family: inherit;
    padding: 0.75rem 1.2rem;
    border-radius: var(--border-radius);
    border-color: var(--surface-100);

    &:enabled {
        &:hover,
        &:focus {
            border-color: var(--surface-200);
        }
    }

    &:not(.p-chips-multiple-container):read-only:not(.p-dropdown-label) {
        background-color: var(--surface-50);

        &:hover,
        &:focus {
            border-color: var(--surface-100);
        }
    }

    &::placeholder {
        color: var(--surface-200);
    }
}

.p-inputgroup {
    > p-inputmask {
        display: flex;
        flex-direction: row;
        flex: 1;

        &:last-child > input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            // border-top-right-radius: var(--border-radius);
            // border-bottom-right-radius: var(--border-radius);
        }
    }
}

.p-inputgroup-addon {
    padding: 0;
    // border-color: var(--surface-100);
    // background-color: var(--surface-50);

    .p-dropdown {
        flex: 1 !important;
        width: 100% !important;

        > .p-dropdown-label {
            padding-left: 1rem;
            padding-right: 0.2rem;
        }
    }

    .p-dropdown,
    .p-dropdown:hover,
    .p-dropdown:focus {
        border-color: transparent;
        background-color: transparent;
    }

    &:first-child {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
    }
}

.p-inputgroup-addon,
.p-inputgroup button,
.p-inputgroup input {
    .p-dropdown {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &:first-child {
        // border-top-left-radius: var(--border-radius);
        // border-bottom-left-radius: var(--border-radius);
    }

    &:last-child {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }
}

.p-input-icon-left,
.p-input-icon-right {
    > i {
        z-index: 1;
        margin-top: -0.7rem;
    }
}
.p-input-icon-right {
    > i:last-of-type {
        margin-right: 1rem;
    }
}

.p-stacked-input {
    > .p-inputtext {
        position: relative;
        z-index: 1;

        + .p-inputtext {
            position: relative;
            z-index: 1;
            margin-top: -1px;
        }

        &:first-child {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:last-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}
