.p-inline-message {
    padding: 0.822rem 0.5rem;
    border-radius: var(--border-radius);
}

.p-messages.custom-message .p-message{
    margin: 0rem 0;
}

.p-messages.custom-message .p-message-info{
    border: 0px;
    background-color:#FFFBF0;
}

@media (max-width: 767px) {
    .p-messages.custom-message .p-message .p-message-wrapper {
        padding: 0.3rem 1.5rem;
    }
}
