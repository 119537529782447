@import 'primeflex3/core/utils';

.p-datepicker {
    padding: 0.2rem;

    table {
        margin: 0;

        td {
            padding: 0;

            > span.p-highlight {
                background-color: var(--primary-color);
                color: var(--primary-color-text);
            }
        }
    }
}
