.p-chips {
    .p-chips-multiple-container {
        padding: 0.679rem 1.2rem;

        .p-chips-input-token {
            padding: 0;

            input {
                font-family: inherit;
                font-size: inherit;
                color: inherit;
            }
        }

        .p-chips-token {
            padding: 0 0.3rem;
            background: var(--primary-color);
            color: var(--text-color);
            border-radius: var(--border-radius);

            .p-chips-token-icon {
                position: relative;
                top: 1px;
            }

            .p-chips-token-label {
                position: relative;
                top: -1px;
            }
        }

        &:not(.p-disabled):hover,
        &:not(.p-disabled):focus {
            border-color: var(--surface-200);
        }
    }
}
