@import 'primeflex3/core/utils';

.p-button {
    padding: 0.75rem 1.2rem;
    text-decoration: none;
    max-height: 44px;
    @include styleclass('line-height-3 font-semibold');

    &:not(.p-button-icon-only) {
        border-radius: var(--border-radius);
    }

    &.p-button-text {
        @include styleclass('text-gray-800 hover:bg-yellow-50');
    }
    
    &.p-button-primary {
        @include styleclass('bg-yellow-500 border-yellow-500 text-yellow-800 border-round transition-colors transition-duration-150');

        &:not(:disabled) {
            @include styleclass('hover:bg-yellow-600 hover:border-yellow-600');
        }

        &.p-button-outlined {
            @include styleclass('bg-transparent');

            &:not(:disabled) {
                @include styleclass('hover:bg-yellow-50 hover:border-yellow-500');
            }
        }
    }
    

    &.p-button-secondary {
        @include styleclass('bg-cyan-500 text-cyan-50 border-round transition-colors transition-duration-150');

        &:not(:disabled) {
            @include styleclass('hover:bg-cyan-600');
        }
    }

    &.p-button-white {
        @include styleclass('surface-0 text-yellow-800 hover:surface-50 transition-colors transition-duration-150 border-200');
    }
}

.p-button-loading {
    > .p-button-loading-icon {
        width: 20px;
        height: 20px;
    }
}

.p-selectbutton .p-button.p-highlight {
    @include styleclass('bg-yellow-500 text-yellow-800 border-yellow-500');
}

.p-button-icon {
    font-size: 1.25rem;
    position: relative;
    top: 1px;
}
