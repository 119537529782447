.p-tabview {
    .p-tabview-nav li {
        &.p-highlight {
            .p-tabview-nav-link {
                border-color: var(--primary-color);
                color: var(--text-color);
            }
        }

        .p-tabview-nav-link {
            padding: 1rem 1.25rem;
        }
    }
}
