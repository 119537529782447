@import 'primeflex3/core/utils';

.p-multiselect {
    border-color: var(--surface-100);
    border-radius: var(--border-radius);
    width: 100%;

    .p-multiselect-label {
        padding: 0.679rem 1.2rem;
    }
}
