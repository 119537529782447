.p-progress-spinner.custom-spinner {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;

    .p-progress-spinner-circle {
        fill: rgba(255, 255, 255, 0.9);
        animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
    }
}

@keyframes custom-progress-spinner-color {
    100%,
    0% {
        stroke: #16697a;
    }
    40% {
        stroke: #489fb5;
    }
    66% {
        stroke: #82c0cc;
    }
    80%,
    90% {
        stroke: #ffa62b;
    }
}

@keyframes custom-progress-spinner-dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}
