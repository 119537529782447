.grid {
    &:not(.grid-nogutter) {
        margin-right: -0.75rem;
        margin-left: -0.75rem;
        margin-top: 0;

        > .col,
        > [class*='col'] {
            padding: 0 0.75rem;
        }
    }
}
