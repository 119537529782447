.p-card {
    box-shadow: none;
    border-radius: var(--border-radius);
    border: 1px solid var(--border-color);

    .p-card-body {
        padding: 1.25rem;
    }

    .p-card-content {
        padding: 0;
    }

    .p-card-header {
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
        padding: 1rem 1.25rem;
        background-color: var(--bluegray-50);
        border-bottom: 1px solid var(--border-color);
    }

    &.p-card-scrollable {
        .p-card-body {
            max-height: 480px;
            overflow-y: auto;
        }
    }
}
