@import '../../../styles/core.scss';
@import '../../../styles/ata.scss';

html {
    width: 100%;
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
}

ata-atau {
    display: flex;
    flex-direction: column;
    flex: 1;
}
