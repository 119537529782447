// stylelint-disable declaration-no-important, selector-max-class
@import 'primeflex3/core/utils';

.pac-container {
    z-index: 1000;
    min-width: 11rem;
    padding-top: 0.5rem;
    margin: 0.125rem 0 0;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    width: auto !important;
    left: 1.5rem !important;
    right: 1.5rem !important;
    @include styleclass('shadow-2');

    .pac-item {
        display: flex;
        align-items: center;
        padding: 0.595rem 1rem;
        font-size: inherit !important;
        line-height: inherit !important;
        color: #212f3d;
        border: 0;

        &:hover,
        &:focus {
            color: #18222c;
            text-decoration: none;
            background-color: #f7f7f8;
        }

        > span:last-child {
            opacity: 0.5;
        }
    }

    .pac-item-query {
        font-size: inherit !important;
    }

    .pac-icon-marker {
        flex-shrink: 0;
        margin-top: 0 !important;
    }
}

.pac-logo::after {
    padding: 1.25rem;
    background-color: #f7f7f8;
    background-position: 1.25rem;
}
