@import 'primeflex3/core/utils';

.p-dialog {
    @include styleclass('m-4 border-none bg-white');
    border-radius: 10px;
    overflow: hidden;
    width: 100%;

    > .p-dialog-header {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-top-right-radius: var(--border-radius);
        border-top-left-radius: var(--border-radius);

        .p-dialog-header-close-icon {
            position: relative;
            top: 1px;
        }

        + .p-dialog-content {
            padding: 0 1.5rem 2rem 1.5rem;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }
    }

    >.p-dialog-content {
        padding: 2rem 1.5rem 2rem 1.5rem;
    }

    // @media (min-width: 576px) {
    //     max-width: 500px;
    // }
}

.p-component-overlay {
    z-index: 2;
}

.p-dialog-mask.p-component-overlay {
    background-color: rgba(217, 217, 217, 0.6);
}