.p-paginator {
    &.p-paginator-bottom {
        > .p-paginator-element,
        > .p-paginator-pages {
            order: 2;
        }

        > .p-paginator-current {
            order: 1;
        }

        > p-dropdown {
            display: flex;
            align-items: center;

            &::before {
                content: "Items per page: ";
            }

            .p-dropdown-label {
                padding-top: 5px;
                padding-bottom: 5px;
            }

            .p-dropdown-trigger-icon {
                position: relative;
                top: 1px;
            }
        }
    }
}