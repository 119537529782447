.p-datatable {
    .p-datatable-tbody tr {
        > td:first-child {
            padding-left: 1.5rem;
        }

        > td:last-child {
            padding-right: 1.5rem;
        }
    }

    .p-datatable-thead tr {
        > th {
            padding: 1rem;
            background-color: #f8f9fa;
            border-width: 0 0 1px 0;
            border-style: solid;
            border-color: #e9ecef;
        }

        > th:first-child {
            padding-left: 1.5rem;
        }

        > th:last-child {
            padding-right: 1.5rem;
        }
    }

    &.p-datatable-hoverable-rows {
        .p-datatable-tbody tr:not(.p-highlight) {
            &:hover {
                background-color: var(--surface-50);
            }
        }
    }

    &.p-datatable-hovered {
        tbody tr {
            cursor: pointer;

            &:hover {
                background-color: var(--surface-50) !important;
            }
        }
    }
}
